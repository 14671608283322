var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        title: "运单轨迹",
        visible: _vm.value,
        width: "100%",
        fullscreen: true,
        "before-close": _vm.cancel,
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.value = $event
        }
      }
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("span", { staticStyle: { "margin-left": "10px", color: "red" } }, [
          _vm._v("图中红色圆圈为装货围栏，蓝色圆圈为卸货围栏")
        ])
      ]),
      _c(
        "baidu-map",
        {
          staticClass: "map",
          attrs: {
            center: _vm.center,
            zoom: _vm.zoom,
            "scroll-wheel-zoom": true
          },
          on: { ready: _vm.handler }
        },
        [
          _c("bm-navigation", {
            attrs: { anchor: "BMAP_ANCHOR_BOTTOM_RIGHT" }
          }),
          _vm._l(_vm.pointArr, function(point, index) {
            return _c("bm-marker", {
              key: index,
              attrs: {
                position: point,
                dragging: false,
                zIndex:
                  index === 0 ||
                  (index === _vm.pointArr.length - 1 && _vm.arrivedStatus)
                    ? 100
                    : 0,
                icon: {
                  url:
                    index === 0
                      ? require("@/img/asset/bdmap/begin.png")
                      : index === _vm.pointArr.length - 1 && _vm.arrivedStatus
                      ? require("@/img/asset/bdmap/end.png")
                      : require("@/img/asset/bdmap/point2.png"),
                  size: { width: 32, height: 32 },
                  opts: { anchor: { width: 16, height: 32 } }
                }
              },
              on: {
                click: function($event) {
                  return _vm.infoWindowOpen($event, point)
                }
              }
            })
          }),
          _c("bml-lushu", {
            attrs: {
              path: _vm.arrPois,
              icon: _vm.icon,
              play: _vm.play,
              speed: _vm.speed,
              autoView: true,
              rotation: true
            },
            on: { stop: _vm.reset1 }
          }),
          _vm._l(_vm.circlePaths, function(circlePath) {
            return _vm.circleShow
              ? _c("bm-circle", {
                  key: _vm.mapPathUpdater,
                  attrs: {
                    center: circlePath.center,
                    radius: circlePath.radius,
                    "stroke-color": circlePath.color,
                    "stroke-opacity": 0.5,
                    "stroke-weight": 2,
                    editing: true
                  },
                  on: { lineupdate: _vm.pathChanged }
                })
              : _vm._e()
          }),
          _vm._l(_vm.polygonPaths, function(polygonPath) {
            return _vm.polygonShow
              ? _c("bm-polygon", {
                  key: _vm.mapPathUpdater,
                  attrs: {
                    path: polygonPath,
                    "stroke-color": polygonPath[0].color,
                    "stroke-opacity": 0.5,
                    "stroke-weight": 3,
                    editing: true
                  },
                  on: { lineupdate: _vm.pathChanged }
                })
              : _vm._e()
          })
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "s-control-l" },
        [
          _c("br"),
          _c("br"),
          _c(
            "div",
            { staticClass: "input-group-inline" },
            [
              _c("Input", {
                staticClass: "control-width",
                staticStyle: { width: "320px" },
                attrs: { disabled: "", placeholder: "请输入设备号" },
                model: {
                  value: _vm.device,
                  callback: function($$v) {
                    _vm.device = $$v
                  },
                  expression: "device"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "input-group-inline" },
            [
              _c("DatePicker", {
                staticStyle: { width: "320px" },
                attrs: {
                  type: "datetimerange",
                  "split-panels": "",
                  placeholder: "设备运行时间",
                  value: _vm.timeRange,
                  format: "yyyy-MM-dd HH:mm:ss",
                  editable: false
                },
                on: { "on-change": _vm.updateTimeRange }
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "98%" },
              attrs: { type: "primary" },
              on: { click: _vm.getTrailByDevice }
            },
            [_vm._v("搜索轨迹")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }