<template>
  <el-dialog
    title="运单轨迹"
    :visible.sync="value"
    width="100%"
    v-loading="loading"
    :fullscreen="true"
    :before-close="cancel"
    append-to-body>
    <div slot="title">
<!--      <span style="font-size: 18px; font-weight: bold">运单轨迹</span>-->
<!--      <span style="margin-left: 10px;">运单号：{{shippingInfo.shippingNoteNumber}}</span>-->
<!--      <span style="margin-left: 5px;">分段分单：{{shippingInfo.serialNumber}}</span>-->
      <span style="margin-left: 10px;color:red;" >图中红色圆圈为装货围栏，蓝色圆圈为卸货围栏</span>
    </div>
    <baidu-map class="map" :center="center" :zoom="zoom" :scroll-wheel-zoom="true" @ready="handler">
      <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-navigation>
      <!-- <bm-polyline v-if="trackFlag" :path="trackPoint" stroke-color="#0091ea" :stroke-opacity="0.8" :stroke-weight="10"
                   :editing="false"></bm-polyline> -->
      <bm-marker v-for="(point,index) in pointArr" :key="index" :position="point" :dragging="false"
                 @click="infoWindowOpen($event,point)" :zIndex="(index===0 || (index===pointArr.length-1&&arrivedStatus))?100:0"
                 :icon="{url: index===0?require('@/img/asset/bdmap/begin.png'):(index===pointArr.length-1&&arrivedStatus?require('@/img/asset/bdmap/end.png'):require('@/img/asset/bdmap/point2.png')),
                  size: {width: 32, height: 32},opts: {anchor: {width: 16, height: 32}}}">
      </bm-marker>

      <!-- <bm-marker v-if="gpsFlag" v-for="(point,index) in phoneArr" :key="index" :position="point" :dragging="false"
                 @click="infoWindowOpenGps($event,point)"
                 :icon="{url: require('@/img/asset/bdmap/point1.png'),
                 size: {width: 32, height: 32},opts: {anchor: {width: 16, height: 32}}}">
      </bm-marker> -->
      <bml-lushu
                 @stop="reset1"
                 :path="arrPois"
                 :icon="icon"
                 :play="play"
                 :speed="speed"
                 :autoView="true"
                 :rotation="true">
      </bml-lushu>

      <bm-circle :key="mapPathUpdater" v-for="circlePath in circlePaths" v-if="circleShow" :center="circlePath.center" :radius="circlePath.radius" :stroke-color="circlePath.color"
                   :stroke-opacity="0.5" :stroke-weight="2" :editing="true" @lineupdate="pathChanged"></bm-circle>

      <bm-polygon :key="mapPathUpdater" v-for="polygonPath in polygonPaths" v-if="polygonShow" :path="polygonPath" :stroke-color="polygonPath[0].color" :stroke-opacity="0.5"
                    :stroke-weight="3" :editing="true" @lineupdate="pathChanged"/>
    </baidu-map>


      <div class="s-control-l">
        <br />
        <br />
        <div class="input-group-inline">
          <Input class="control-width" disabled v-model="device" placeholder="请输入设备号" style="width: 320px;"/>
        </div>
        <div class="input-group-inline">
          <DatePicker type="datetimerange" split-panels placeholder="设备运行时间" :value="timeRange"
                      @on-change="updateTimeRange" format="yyyy-MM-dd HH:mm:ss" :editable="false"
                      style="width: 320px;"></DatePicker>
        </div>
        <el-button type="primary" style="width:98%;" @click="getTrailByDevice">搜索轨迹</el-button>
      </div>


  </el-dialog>
</template>

<script>
import {getTrack} from "@/api/schedule/shipping";
import {BmlLushu} from 'vue-baidu-map'
import {ajax, formateDate} from '@/libs/util';
import { bd09_to_gcj02, gcj02_to_bd09 } from '@/libs/BdCoordConvertor';
import GeoDistance from '@/libs/GeoDistance';
import moment from "moment/moment";
let _cacheTableData = [];
export default {
  name: "trail.vue",
  data() {
    let yesterday = moment(Date.now()).format('YYYY-MM-DD 00:00:00');
    let today = moment().format('YYYY-MM-DD 23:59:59');
    return {
      trackList: [],
      device: '',
      locateType: 0,
      timeRange: [new Date(yesterday), new Date(today)],
      circlePaths: [],
      polygonPaths:[],
      center: {lng: 116.404, lat: 39.915},
      zoom: 10,
      shippingInfo: {},
      pointArr: [],
      devices: [],
      currDevice: '',
      trackByDevice: {},
      // countList: [],
      map: {},
      play: false,
      loading: false,
      icon: {
        url: require('@/img/asset/bdmap/truck1.png'),
        size: {width: 42, height: 30},
        opts: {anchor: {width: 21, height: 15},imageSize:{width: 42, height: 25}}
      },
      lushu: '',
      speed: 20000,
      minSpeed: 20000,
      maxSpeed: 100000,
      speedMarks: {20000: '20km/s', 40000: '40km/s', 60000: '60km/s', 80000: '80km/s'},
      arrPois:[],
      arrivedStatus:true,
      mapReadyCallbacks: [],
      circleShow: false,
      polygonShow: false,
      mapPathUpdater: 1,

    }
  },
  components: {
    BmlLushu
  },
  props: {
    value: Boolean
  },
  computed: {
    currTrack() {
      return this.trackByDevice[this.currDevice] && this.trackByDevice[this.currDevice].track || []
    }
  },
  methods: {

    getTrailByDevice() {
      //console.log(this.timeRange);
      // let queryData = this.getTrailQueryData();
      // if (!queryData) return;
      // //console.log(startTime,endTime);
      // //this.map && this.map.clearMap();
      // this.pathSimplifierIns && this.pathSimplifierIns.setData();
      // // this.loading = true;
      // if(queryData.startTime){
      //   queryData.startTime = (new Date(queryData.startTime).getTime()/1000).toFixed(0)
      // }
      // if(queryData.endTime){
      //   queryData.endTime = (new Date(queryData.endTime).getTime()/1000).toFixed(0)
      // }
      // 定位模式是全部时显示设备的异常点
      // ajax.post('/device/get_track',{
      //   ...queryData
      // },(res)=> {
      //   let trackList = res.data || [];
      //   if (trackList.length === 0) {
      //     this.$Message.error('该时间段暂无轨迹！');
      //     this.trackList = [];
      //     _cacheTableData = [];
      //     this.totalItems = 0;
      //     this.tableData = [];
      //     return {};
      //   }
      //   this.trackList = trackList
      //
      //   this.setPathData(trackList);
      //
      // },(error)=> {
      //   this.$Message.error(error.message || "服务器异常，请重试");
      //   this.loading = false;
      // });
      this.getTrackByDevice()

    },
     ajaxPost(url, data) {
          return new Promise((resolve, reject) => {
        ajax.post(url, data, (res) => {
          resolve(res);
        }, (error) => {
          reject(error);
        });
      });
    },

    async  getTrackByDevice() {
      // console.log(this.timeRange);
      let queryData = this.getTrailQueryData();
      if (!queryData) return;

      // 清空当前路径数据
      // this.pathSimplifierIns && this.pathSimplifierIns.setData();

      // 格式化查询时间
      if (queryData.startTime) {
        queryData.startTime = (new Date(queryData.startTime).getTime() / 1000).toFixed(0);
      }
      if (queryData.endTime) {
        queryData.endTime = (new Date(queryData.endTime).getTime() / 1000).toFixed(0);
      }

      // 发送 AJAX 请求并等待结果
      try {
        const res = await this.ajaxPost('/device/get_track', queryData);

        let trackList = res.data || [];
        if (trackList.length === 0) {
          this.$Message.error('该时间段暂无轨迹！');
          this.trackList = [];
          _cacheTableData = [];
          this.totalItems = 0;
          this.tableData = [];
          return {};
        }
        this.trackList = trackList;

        this.setPathData(trackList);
      } catch (error) {
        this.$Message.error(error.message || "服务器异常，请重试");
      } finally {
        this.loading = false;
      }
    },


    setPathData(trackList = []) {

      gcj02_to_bd09(trackList).then(pts => {

        let trackPoint =pts.map((item) => {
          return new BMap.Point(item.lng, item.lat);
        })


        let polyline = new BMap.Polyline(trackPoint);
        console.log("绘制轨迹")
        this.map.addOverlay(polyline);
        this.setZoom(trackPoint);
        this.arrPois = trackPoint
      })
      // this.countList.push(...pointArr);
    },
    pathChanged(e) {
      this.updateForm(e.target, this.form.fenceShape === 1)
    },
    updateForm(overlay, isCircle) {
      if (isCircle) {
        this.form = Object.assign({}, this.form, {
          triggerScope: overlay.getRadius(),
          fencePoints: [{longitude: overlay.getCenter().lng, latitude: overlay.getCenter().lat}],
          fencePath: `(${overlay.getCenter().lng},${overlay.getCenter().lat},${overlay.getRadius()})`
        });
      } else if (overlay.getPath) {
        this.form = Object.assign({}, this.form, {
          triggerScope: undefined,
          fencePoints:  overlay.getPath().map(p => { return {longitude: p.lng, latitude: p.lat } }),
          fencePath: overlay.getPath().map(p => `(${p.lng},${p.lat})`).join(',')
        });
      }
    },


    getTrailQueryData() {
      let {device, locateType} = this;
      if (!device) {
        this.$Message.error('请输入设备号！');
        return;
      }

      let startTime2 = moment(this.timeRange[0]).unix();
      let endTime2 = moment(this.timeRange[1]).unix();
      let startTime = moment(this.timeRange[0]).format('YYYY-MM-DD HH:mm:ss');
      let endTime = moment(this.timeRange[1]).format('YYYY-MM-DD HH:mm:ss');

      if ((endTime2 - startTime2) > 180 * 24 * 60 * 60) {
        this.$Message.error('时间跨度大于180天，请缩小时间范围。');
        return;
      }
      return {
        device: device,
        locateType: locateType ? locateType : '',
        startTime: startTime,
        endTime: endTime
      };
    },


    updateTimeRange(str, date) {
      // str[1]=str[1].replace('00:00:00','23:59:59')
      this.timeRange = str;
      //this.getTrailByDevice()
    },




    reset1() {
      this.play = !this.play;
    },
     // 加载围栏数据
    _loadHurdle(row) {
      var promise = undefined;
      row = _.cloneDeep(row)
        // promise = Promise.resolve(row);
      promise = gcj02_to_bd09(row.fenceInfoDTOList, 'latitude', 'longitude')
          .then(pts => {
            row.fenceInfoDTOList = pts
            return row
          })

      return promise.then((row) => {

        this.form = Object.assign({}, row);

        row.fenceInfoDTOList.forEach(fence => {
          if(!fence.polygonType){
             if (fence.fenceType==1){
               fence.color="#EC4141"
             }else{
               fence.color="blue"
             }
            this.circlePaths.push({
              center: { lng: fence.longitude, lat: fence.latitude },
              radius: fence.triggerScope,
              color:fence.color
            })
            let tmpCP={
              center: { lng: fence.longitude, lat: fence.latitude },
              radius: fence.triggerScope
            }
            this.center = tmpCP.center

            this.form = Object.assign({}, this.form, {
              triggerScope: fence.triggerScope,
              fencePoints: fence,
              fencePath: `(${fence.longitude},${fence.latitude},${fence.triggerScope})`
            });

            //calc bounds, set viewport
            let circle = new BMap.Circle(new BMap.Point(this.center.lng, this.center.lat), fence.triggerScope)
            let bounds = circle.getBounds()
            let viewportPoints = [bounds.getSouthWest(), bounds.getNorthEast()]
            let vp = this.map.getViewport(viewportPoints, {margins: [20, 20, 20, 20]})
            this.zoom = vp.zoom - 2;

            this.circleShow = true;
            this.mapPathUpdater ++;
            }else{

            let fenceListByDevice = row.fenceInfoDTOList.reduce((r, a) => {

              const key = `${a.baseFenceId}_${a.fenceType}`;
              r[key] = r[key] || [];

              r[key].push(a);
                // r[a.baseFenceId] = r[a.baseFenceId] || [];
                // r[a.baseFenceId].push(a);
                return r;
              }, Object.create(null));
            let fences= Object.values(fenceListByDevice)
            this.polygonPaths=[]
            fences.forEach(fence=>{
               let polygonPath = fence.map(p => {
               if (p.fenceType==1){
               return { lng: p.longitude, lat: p.latitude ,color:"#EC4141"}
                 }else{
               return { lng: p.longitude, lat: p.latitude ,color:"blue"}
               }
               })
               this.polygonPaths.push(polygonPath)
               })
            //calc bounds, set viewport
            let viewportPoints = this.polygonPaths[0].map(p => new BMap.Point(p.lng, p.lat))
            let vp = this.map.getViewport(viewportPoints, {margins: [20, 20, 20, 20]})
            this.zoom = vp.zoom;
            this.center = vp.center;
            // this.center = this.polygonPath[0];
           // this.circleShow = false;
            this.polygonShow = true;
            this.mapPathUpdater ++;
            }
         }
        )
      })
    },

    handler({BMap, map}) {
      console.log(BMap, map);
      this.BMap = BMap;
      this.map = map;
      let callbacks = this.mapReadyCallbacks;
      this.mapReadyCallbacks = [];

      callbacks.forEach(callback => {
        callback();
      })
    },
    init(row) {
      this.device = row.device;
      if (this.map) {
        this.$init(row)
      } else {
        this.mapReadyCallbacks.push(() => this.$init(row))
      }
    },

    $init(row) {
      if (!this.map) {
        return;
      }
      this.loading = true;
      this.shippingInfo = Object.assign({}, row);
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.play = false;
          this.devices = [];
          this.currDevice = '';
          this.trackByDevice = {};
          this.arrPois = [];

          if (this.map) {
            // this.map.clearOverlays();
          }

          this.arrivedStatus = row.shipStatus >= 5;

          // 去掉获取轨迹数据的部分，只保留绘制围栏的功能
          this._loadHurdle(row).finally(() => {
            this.loading = false;
          });
        }, 1000);
      });
    },




    // $init(row) {
    //   if (!this.map) {
    //     return
    //   }
    //   this.loading = true;
    //   this.shippingInfo = Object.assign({}, row);
    //   return new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //       this.play = false;
    //       // this.countList = [];
    //       this.devices = [];
    //       this.currDevice = '';
    //       this.trackByDevice = {};
    //       this.arrPois=[];
    //       if(this.map) {
    //         this.map.clearOverlays();
    //       }
    //
    //       this.arrivedStatus = row.shipStatus>=5;
    //       getTrack({shippingNoteNumber: row.shippingNoteNumber, serialNumber: row.serialNumber})
    //       .then(res => {
    //         //convert
    //         return gcj02_to_bd09(res.data).then(pts => {
    //           res.data = pts;
    //           return res;
    //         })
    //       }).then(res => {
    //         // group by device
    //         let trackListByDevice = res.data.reduce((r, a) => {
    //             a.time = a.locateTime;
    //             r[a.device] = r[a.device] || [];
    //             r[a.device].push(a);
    //             return r;
    //           }, Object.create(null));
    //         // sort by locateTime
    //         this.devices = Object.keys(trackListByDevice);
    //         this.devices.forEach(device => {
    //           let trackList = trackListByDevice[device].sort((t1, t2) => t1.locateTime - t2.locateTime);
    //           let start = trackList.length ? trackList[0] : null;
    //           let end = trackList.length ? trackList[trackList.length - 1] : null;
    //           this.trackByDevice[device] = {
    //             track: trackList,
    //             start: start,
    //             end: end
    //           }
    //           if (!this.currDevice) {
    //             this.currDevice = device;
    //           }
    //         });
    //         this.showCurrPoly();
    //         this._loadHurdle(row)
    //       }).finally(() => {
    //         this.loading = false;
    //       })
    //     }, 1000)
    //   })
    // },


    showCurrPoly(clear) {
      this.play = false;
      if (clear) {
        if(this.map) {
          this.map.clearOverlays();
        }
      }
      if (this.currDevice && this.trackByDevice[this.currDevice].start) {
        this.showPoly(this.currDevice, this.trackByDevice[this.currDevice].track, this.trackByDevice[this.currDevice].start, this.trackByDevice[this.currDevice].end);
      }
    },
    /**
     * @param device 设备
     * @param trackList 路径
     * @param startMarker 开始点
     * @param endMarker 结束点
     * @param useRoute 是否使用路径规划
     */
    showPoly(device, trackList, startMarker, endMarker, useRoute=false) {
      // const BMap = this.BMap;

      let start = new BMap.Point(startMarker.lng, startMarker.lat);
      let end = new BMap.Point(endMarker.lng, endMarker.lat);

      // let pointArr = trackList.map((item) => {
      //   return {lng: item.lng, lat: item.lat, time: item.locateTime};
      // });
      let trackPoint = trackList.map((item) => {
        return new BMap.Point(item.lng, item.lat);
      });
      // this.countList.push(...pointArr);
      this.setZoom(trackPoint);

      if (useRoute) {
        let waypoints = []
        let num = Math.round(trackList.length / 10);
        for (let i = 0; i < trackList.length; i++) {
          if (i % num === 0 && waypoints.length <= 10) {
            waypoints.push(new BMap.Point(trackList[i].lng, trackList[i].lat));
          }
        }

        let driving = new BMap.DrivingRoute(this.map, {
          onSearchComplete: (results) => {
            if (driving.getStatus() == BMAP_STATUS_SUCCESS) {
              let plan = driving.getResults().getPlan(0);
              let num = plan.getNumRoutes();
              this.arrPois = [];
              for (let j = 0; j < num; j++) {
                let pts = plan.getRoute(j).getPath();    //通过驾车实例，获得一系列点的数组
                this.arrPois = this.arrPois.concat(pts);
                let polyline = new BMap.Polyline(pts);
                this.map.addOverlay(polyline);
              }
              this.calculateSpeed(this.arrPois);
            }
          }
        });
        driving.search(start, end, {waypoints: waypoints});
      } else {
        this.arrPois = trackPoint
        let polyline = new BMap.Polyline(trackPoint);
        this.map.addOverlay(polyline);
        this.calculateSpeed(this.arrPois);
      }

      const startLabel = new this.BMap.Label(device, {offset: new this.BMap.Size(26, 0)})
      const startIcon = new this.BMap.Icon(require('@/img/asset/bdmap/begin.svg'), new this.BMap.Size(32, 32), {anchor: new this.BMap.Size(16, 32)});
      const markerStart = new this.BMap.Marker(start, {icon: startIcon});
      markerStart.setLabel(startLabel)
      markerStart.setZIndex(101);
      this.map.addOverlay(markerStart); // 自定义起点定位图标
      const endLabel = new this.BMap.Label(device, {offset: new this.BMap.Size(26, 0)})
      const endIcon = new this.BMap.Icon(require('@/img/asset/bdmap/end.svg'), new this.BMap.Size(32, 32), {anchor: new this.BMap.Size(16, 32)});
      const markerEnd = new this.BMap.Marker(end, {icon: endIcon});
      markerEnd.setLabel(endLabel)
      markerEnd.setZIndex(101);
      this.map.addOverlay(markerEnd); // 自定义终点定位图标

    },
    calculateSpeed(pts) {
      if (!pts || !pts.length) {
        return
      }
      let distance = GeoDistance.pathDistance(pts, true);
      //max speed: 2 s to finish
      let maxSpeed = Math.round(distance / 2);
      let speeds = [0, 1, 2, 3, 4, 5]
          .map(i => maxSpeed / 5 * i)
          .map(mps => [mps, (Math.round(mps/10) > 0 ? Math.round(mps/10)/100 + 'km/s' : '0')])
      this.minSpeed = speeds[1][0];
      this.maxSpeed = speeds[4][0];
      this.speedMarks = Object.fromEntries(speeds.slice(1, 5));
      this.speed = speeds[2][0];
    },
    infoWindowOpen({type, point, target}, item) {
      let geoc = new BMap.Geocoder();
      geoc.getLocation(point, (rs) => {
        let addComp = rs.addressComponents;
        let msgTitle = addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber + "<br/>" + "时间: " + formateDate(item.time*1000) + "<br/>" + "经度: " + point.lng + "<br/>" + "纬度: " + point.lat;
        let opts = {
          width: 120,
          height: 90
        }
        let infoWindow = new BMap.InfoWindow("地址: " + msgTitle, opts);  // 创建信息窗口对象
        target.openInfoWindow(infoWindow, this.center);
      });
    },
    infoWindowOpenGps({type, point, target}, item) {
      let geoc = new BMap.Geocoder();
      geoc.getLocation(point, (rs) => {
        let addComp = rs.addressComponents;
        let msgTitle = addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber + "<br/>" + "时间: " + formateDate(item.time*1000) + "<br/>" + "经度: " + point.lng + "<br/>" + "纬度: " + point.lat;
        let opts = {
          width: 120,
          height: 90
        }
        let infoWindow = new BMap.InfoWindow("地址: " + msgTitle, opts);  // 创建信息窗口对象
        target.openInfoWindow(infoWindow, this.center);
      });
    },
    /**
     * 确认
     */
    confirm: function () {
      this.$emit('map-confirm')
    },
    /**
     * 取消
     */
    cancel: function () {
      this.$emit('input', false);
    },
    pointClick(val) {
      let point = new BMap.Point(val.lng, val.lat);
      let geoc = new BMap.Geocoder();
      geoc.getLocation(point, (rs) => {
        let addComp = rs.addressComponents;
        let msgTitle = addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber + "<br/>" + "时间: " + formateDate(val.time*1000) + "<br/>" + "经度: " + point.lng + "<br/>" + "纬度: " + point.lat;
        let infoWindow = new BMap.InfoWindow("地址: " + msgTitle, {width: 120, height: 90});  // 创建信息窗口对象
        this.map.openInfoWindow(infoWindow, point);
      });
    },
    setZoom(bPoints) {
      let view = this.map.getViewport(eval(bPoints));
      let mapZoom = view.zoom;
      let centerPoint = view.center;
      this.map.centerAndZoom(centerPoint, mapZoom);
    }
  }
}
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 90vh;
}

::v-deep .el-dialog__body {
  padding: 0 20px;
}

.left-content {
  top: 54px;
  left: 20px;
  width: 300px;
  height: 90vh;
  //border: 1px solid red;
  z-index: 100;
  position: absolute;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  .left-header {
    width: 100%;
  }

  .left-item {
    border-top: 1px solid #eee;
    cursor: pointer;
  }
  .el-form-item.compact {
    margin-bottom: 0;
  }
}

.scroller {
  overflow-y: auto;
  height: 100%;
}

.scroller::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scroller::-webkit-scrollbar-track {
  background-color: #F5F5F5;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

.scroller::-webkit-scrollbar-thumb {
  background-color: #c8c8c8;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

.play {
  padding-left: 320px;
  position: absolute;
  width: 100%;
  height: 50px;
  z-index: 100;
  background-color: #fff;
}

.s-control-l {
  /*display: flex;*/
  /*flex-direction: row;*/
  position: absolute;
  border: 1px solid #efefef;
  left: 20px;
  top: 50px;
  z-index: 99999;
  background-color: #FFF;
  padding: 10px 10px 10px 10px;
  width: 350px;
  // height: 100%;
}

</style>
