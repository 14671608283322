<style scoped lang="less">
    .deviceLabel {
        margin-left: 10px;
        font: bold 14px Georgia;
    }

    .line {
        background-color: #E5E5E5;
        height: 1px;
        margin-top: 5px;
    }

    .pop-width {
        width: 200px;
    }

    .col-label {
        display: inline-block;
    }

    .pop {
        .ivu-row, .ivu-row-flex {
            margin-bottom: 20px;
        }
    }
    .content{padding:0;}
</style>

<template>
    <div class="content">
        <div id="search_top" class="search-top">
            <div class="input-group-inline right">
                <Button type="primary" icon="md-search" @click="dataQuery">查询</Button>
                <Button icon="trash-a" @click="clear" style="margin-left:5px;">清空</Button>
            </div>

            <div class="input-group-inline">
                <label>设备号：</label>
                <Input class="control-width" type="textarea" v-model="queryData.devices" placeholder="请输入设备号"
                       :autosize="{maxRows: 2,minRows: 1}"></Input>
            </div>
            <div class="input-group-inline">
                <label>设备状态：</label>
                <Select class="custom-control-width" v-model="queryData.status">
                    <Option value="">全部</Option>
                    <Option value="1">在线</Option>
                    <Option value="2">离线</Option>
                    <Option value="3">未激活</Option>
                </Select>
            </div>
            <div class="input-group-inline">
                <label>充电状态：</label>
                <Select class="custom-control-width" v-model="queryData.chargerState">
                    <Option value="">全部</Option>
                    <Option value="0">未充电</Option>
                    <Option value="1">充电中</Option>
                </Select>
            </div>
            <div class="input-group-inline">
                <label>电量≤：</label>
                <Select class="custom-control-width" style="width:90px;" v-model="queryData.powerRate">
                    <Option value="100">100%</Option>
                    <Option value="90">90%</Option>
                    <Option value="80">80%</Option>
                    <Option value="70">70%</Option>
                    <Option value="60">60%</Option>
                    <Option value="50">50%</Option>
                    <Option value="40">40%</Option>
                    <Option value="30">30%</Option>
                    <Option value="20">20%</Option>
                    <Option value="10">10%</Option>
                    <Option value="5">5%</Option>
                </Select>
            </div>
            <div class="input-group-inline" >
                <label style="line-height: 34px">设备型号：</label>
                <Treeselect
                    style="float:right;width: 200px;"
                    class="custom-control-width"
                    v-model="queryData.pids"
                    :multiple="true"
                    :options="deviceModeList"
                    :searchable="false"
                    placeholder="请选择">
                </Treeselect>
            </div>
            <div class="input-group-inline">
                <label>车牌号：</label>
                <Input class="control-width" type="text" v-model="queryData.carNumber" placeholder="请输入车牌号"
                       ></Input>
            </div>
            <div class="input-group-inline">
                <label>所属组织：</label>
                <Input class="control-width" type="text" v-model="queryData.companyName" placeholder="请输入所属组织"
            ></Input>
            </div>

            <div class="input-group-inline">
              <label>到期时间：</label>
                <el-date-picker
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="queryData.timerScope"
                ></el-date-picker>
                <!-- <label>到期时间：</label>
                <Input class="control-width" type="text" v-model="queryData.companyName" placeholder="请输入所属组织"
            ></Input> -->
            </div>

        </div>

        <Table id="table" highlight-row stripe border style="overflow: visible;"
               ref="table"
               size="small"
               :loading="loading"
               :height="tableHeight"
               :columns="columns"
               :data="tableData"></Table>
        <div id="page-box" class="page-box">

            <div class="page-foot">
                <Checkbox v-model="isAll" @on-change="handleSelectAll">{{isAll ? "取消" : "全选"}}</Checkbox>
                <Button type="primary" @click="startBatchReportedRate($refs.table.getSelection())">指令设置</Button>
                <Button type="primary" @click="deviceDistri($refs.table.getSelection())">分配设备</Button>
            </div>

            <Page :total="total" :current="current" size="small" :page-size="pageSize" show-total
                  @on-change="changePage"></Page>

            <div style="float: right;margin:0 20px;">
                <Button size="small" type="default" style="margin-right: 5px;" @click="exportExcel">导出</Button>
            </div>
        </div>

        <!--设备导入结果-->
        <Modal width="400" v-model="showUploadState" title="设备导入">
            <div>
                <transition name="fade">
                    <Progress hide-info
                              :stroke-width="2"
                              :status="uploadStatus"
                              :percent="uploadPercentage"></Progress>
                </transition>
                <div style="word-break: break-all;">{{uploadMessage}}</div>
            </div>
        </Modal>

        <!--服务设置弹窗-->
        <Modal width="650" v-model="showSerSetting" :title="showSerFlag == '1' ? '服务设置' : '服务设置(批量设备)'" @on-ok="sendSetting">
            <div class="sms-title" style="padding:0 0 15px 0;" v-if="showSerFlag != '1'">
                <div>
                    当前已选择 {{currentDeviceNum}} 个设备
                </div>
            </div>
            <div class="sms-title" style="padding:0 0 15px 0;">
                <label style="line-height: 34px">到期时间：</label>
                <DatePicker type="date" v-model="daloagTime" placeholder="请选择" style="width: 200px"></DatePicker>
            </div>
        </Modal>

        <!--分配设备弹窗-->
        <Modal width="650" v-model="showDeviceDistri" :title="showSerFlag == '1' ? '分配设备' : '分配设备(批量设备)'" @on-ok="setDeviceDistri">
            <div class="sms-title" style="padding:0 0 15px 0;" v-if="showDeviceFlag != '1'">
                <div>
                    当前已选择 {{currentDeviceNum}} 个设备
                </div>
            </div>
            <div class="sms-title" style="padding:0 0 15px 0;">
                <label style="line-height: 34px">分配组织：</label>
                <Select v-model="deviceDistriCompanyId" class="control-width">
                    <Option v-for="item in companyJson" :value="item.id" :key="item.id">{{ item.company }}</Option>
                </Select>
            </div>
        </Modal>

        <!--查看日志弹窗-->
        <Modal width="1024" v-model="showLogState" title="设备日志">
            <Tabs type="card" :value="tabActiveIndex" @on-click="handleTabsChange">
                <TabPane label="操作日志">
                    <Table highlight-row stripe border
                           size="small"
                           :height="logTableHeight"
                           :loading="logLoading"
                           :columns="configLogColumns"
                           :data="logTableData"></Table>
                </TabPane>
                <TabPane label="指令日志">
                    <Table highlight-row stripe border
                           size="small"
                           :height="logTableHeight"
                           :loading="logLoading"
                           :columns="directiveLogColumns"
                           :data="logTableData"></Table>
                </TabPane>
            </Tabs>

            <Page :total="logTotal" :current="logCurrent" size="small" :page-size="logPageSize" show-total
                  @on-change="changeLogPage" style="text-align: center;margin-top: 10px"></Page>

            <div slot="footer"></div>
        </Modal>

      <!---->
      <!--查看运行中围栏-->
      <Modal width="1480" v-model="showCurrentFence" title="正在运行围栏">
        <el-table
            border
            stripe
            v-loading="loading"
            :data="fenceList"
        >
<!--          <el-table-column label="设备好" fixed align="center" type="index" />-->
          <el-table-column label="设备号" align="center" prop="device" width="250">
          </el-table-column>

          <el-table-column label="装货围栏" prop="loadingFenceName"  align="center"  width="250"/>
          <el-table-column label="卸货围栏" prop="unloadFenceName"  align="center"  width="250"/>

          <el-table-column label="创建时间" align="center" prop="createTime" width="250">
          </el-table-column>

          <el-table-column label="运单号"  align="center" prop="shippingNoteNumber"  width="250"/>

          <el-table-column label="操作" fixed align="center" width="250" class-name="small-padding fixed-width">
            <template slot-scope="scope">
              <el-button
                  size="small"
                  type="text"
                  icon="el-icon-location"
                  @click="showTrackHandeler(scope.row)"
              >详情</el-button>
            </template>
          </el-table-column>

        </el-table>

        <div slot="footer"></div>
      </Modal>

        <!--查看温湿度趋势-->
        <Modal width="1024" v-model="showTemperatureState" title="温湿度趋势">
            <Tabs type="card" :value="tabActiveTem" @on-click="handleTabsChangeTem">
                <TabPane label="温度趋势">
                    <div id="temperature_echarts" style="width:960px;height:300px;"></div>
                </TabPane>
                <TabPane label="湿度趋势">
                    <div id="humidity_echarts" style="width:960px;height:300px;"></div>
                </TabPane>
            </Tabs>
            <div slot="footer"></div>
        </Modal>

        <!--短信发送-->
        <Modal width="650" v-model="showSmsModal" title="短信发送" @on-ok="sendSmsDo">
            <div class="sms-title" style="padding:0 0 15px 0;">
                <div v-if="isSingleSendSms">
                    设备号：{{currentDevice}}
                    <span style="margin-left:20px;">ICCID:{{currentICCID}}</span>
                </div>
                <div v-else>
                    当前已选择 {{currentDeviceNum}} 个设备
                </div>
            </div>
            <div class="sms-content">
                <Input v-model="smsContent" :maxlength="200" type="textarea" placeholder="请输入短信内容" style="width:100%" />
            </div>
        </Modal>
        <Modal width="400" v-model="isCarNumberDialogShown" title="修改车牌号" @on-ok="bindCarNumber">
            <div style="padding:0 0 15px 0;">
                 <label style="line-height: 34px">车牌号：</label>
                <Input v-model="carNumber" placeholder="请输入车牌号" style="width:80%" />
            </div>
        </Modal>
<!--      <baidu-map-track ref="mapTrack" v-model="dialogMapTrackVisible"></baidu-map-track>-->

      <fence-and-track ref="mapTrack" v-model="dialogMapTrackVisible"></fence-and-track>

    </div>
</template>

<script>

    import Treeselect from '@riophae/vue-treeselect'
    // import the styles
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'

    import {ajax,ajaxBlob,formateDate,getDayBefore,convertToExcel} from '../../libs/util';
    import tableHelper from '../../components/mixins/table-helper.vue';
    import deviceMix from '../../components/mixins/device-mix';
    import moment from 'moment';
    import DictTag from "@/views/schedule/components/dictTag.vue";
    import {listShipping,fenceList,fenceDetail} from "@/api/schedule/shipping";
    import BaiduMapTrack from "@/views/schedule/components/BaiduMap/track.vue";

    import FenceAndTrack from "@/views/schedule/components/BaiduMap/fenceAndTrack.vue";

    const CHART_OPTIONS = {
        humidity: {
            legend_name: `湿度(%RH)`,
            title_text: '湿度趋势',
            y_min: 0,
            y_max: 100,
            unit: '%RH',
            normalColor: '#0DCE8E'
        },
        temperature: {
            legend_name: `温度(℃)`,
            title_text: '温度趋势',
            y_min: -40,
            y_max: 125,
            unit: '℃',
            normalColor: '#5F6FF8'
        }
    };

    export default {
        components:{
          BaiduMapTrack,
          DictTag,
          Treeselect,
          FenceAndTrack
        },
        mixins: [tableHelper, deviceMix],
        data() {

            let routeQuery = this.$route.query || {};
            let queryData = {
                companyName:'',
                carNumber:'',
                chargerState:'',
                powerRate:'',
                devices: '',
                phone: '',
                company: '',
                iccid: '',
                deliveryStatus: '0',
                cardStatus:'',
                status: '',
                platform: '',
                expireTime:'',
                expireTimeString:'',
                pids:[],
                cardTime:[],
                startValidDate:'',
                endValidDate:'',
                timerScope:[],
                ...routeQuery
            };

            return {
                dialogMapTrackVisible: false,
                fenceList:[],
                fenceInfoLsit:[],
                deviceDistriCompanyId:'',
                companyJson:[],
                queryData: queryData,
                isAll: false,
                current: 1,
                pageSize: 50,
                tableHeight: '',
                total: 0,
                tableData: [],
                deviceModeList:[],
                loading: false,
                columns: [
                    {
                        type: 'selection',
                        width: 60,
                        align: 'center'
                    },
                    {
                        type: 'index',
                        title: '序号',
                        width: 70,
                        align: 'center'
                    },
                    {
                        title: '操作',
                        key: 'action',
                        width: 70,
                        align: 'center',
                        render: this.actionRender
                    },
                    {
                        title: '设备型号',
                        width: 130,
                        key: 'productModel'
                    },
                    {
                        title: '设备号',
                        width: 180,
                        key: 'device',
                    },
                    {
                        title: '设备状态',
                        width: 130,
                        key: 'statusName'
                    },
                     {
                        title: '设备服务到期时间',
                        width: 90,
                        key: 'expireTimeStr'
                    },
                    {
                        title: '定位模式',
                        width: 130,
                        key: 'modeName'
                    },
                    {
                        title: '定位周期',
                        width: 130,
                        key: 'reportedRate',
                        render: (h, params) => {
                            const {mode, reportedRate} = params.row;
                            let dw = "";
                            switch (mode) {
                                case 0:
                                    dw = "分钟/次";
                                    break;
                                case 1:
                                    dw = "秒/次";
                                    break;
                                default:
                                    break;
                            }

                            return h('span', reportedRate ? reportedRate + dw : '');
                        }
                    },
                    {
                        title: '电量',
                        width: 80,
                        align: 'center',
                        // render: (h, params) => {
                        //     if(params.row.powerRate){
                        //         return h('span', `${params.row.powerRate}%`);
                        //     }else{
                        //         return '';
                        //     }
                        // }

                      render: (h, params) => {
                        if (params.row.statusName === '离线') {
                          return h('span', '--');
                        } else if (params.row.powerRate) {
                          return h('span', `${params.row.powerRate}%`);
                        } else {
                          return '';
                        }
                      }
                    },
                    {
                        title: '充电状态',
                        width: 130,
                        key: 'chargerStateName',
                      //   render: (h, params) => {
                      //   if (params.row.statusName === '离线') {
                      //     return h('span', '--');
                      //   } else {
                      //     return h('span', params.row.chargerStateName);
                      //   }
                      // }
                      render: (h, params) => {
                        if (params.row.statusName === '离线') {
                          return h('span', '--');
                        } else if (params.row.powerRate === 100) {
                          return h('span', '已充满');
                        } else {
                          return h('span', params.row.chargerStateName);
                        }
                      }
                    },
                    {
                        title: '所属组织',
                        width: 200,
                        key: 'companyName'
                    },
                    {
                        title: '车牌号',
                        width: 200,
                        key: 'carNumber'
                    },
                    {
                        title: '最后一次定位时间',
                        width: 150,
                        key: 'locateTimeStr',
                    },
                    {
                        title: '定位类型',
                        width: 90,
                        key: 'locateTypeName'
                    }
                ],
                addLoading: false,
                uploadData: {service: '/device/import_through_excel'},
                showUploadState: false,
                showSerSetting: false,
                showSerFlag: '',
                showDeviceFlag:'',
                daloagTime: '',
                daloagDevice: '',
                uploadPercentage: 0,
                uploadMessage: '',
                uploadStatus: 'active',
                showSmsModal:false,
                chartCurrentData:{},
                //查看日志
                showLogState: false,
                showCurrentFence: false,
                showTemperatureState: false,
                showDeviceDistri:false,
                logLoading: true,
                tabActiveIndex: 0,
                tabActiveTem: 0,

                logDevice: '',
                temperatureDevice:'',
                configLogColumns: [
                    {
                        type: 'index',
                        title: '序号',
                        width: 60,
                        align: 'center'
                    },
                    {
                        key: 'content',
                        title: '操作类型',
                        align: 'center'
                    },
                    {
                        key: 'operatorName',
                        title: '操作人',
                        align: 'center'
                    },
                    {
                        key: 'created',
                        title: '操作时间',
                        align: 'center',
                        render: (h, params) => {
                            return h('span', formateDate(params.row.created * 1000));
                        }
                    }
                ],
                directiveLogColumns: [
                    {
                        type: 'index',
                        title: '序号',
                        width: 60,
                        align: 'center'
                    },
                    {
                        key: 'logOperator',
                        title: '操作人',
                        width: 120,
                        align: 'center'
                    },
                    {
                        key: 'cmdContent',
                        title: '指令内容',
                        width: 150,
                        align: 'center',
                    },
                    {
                        key: 'cmdTypeStr',
                        title: '指令类型',
                        width: 120,
                        align: 'center',
                    },
                    {
                        key: 'created',
                        title: '下发时间',
                        width: 150,
                        align: 'center',
                        render: (h, params) => {
                            return h('span', formateDate(params.row.created * 1000));
                        }
                    },
                    {
                        key: 'resultMsg',
                        title: '下发状态',
                        width: 180,
                        align: 'center',
                        render: (h, params) => {
                            if (!params.row.resultMsg) {
                                params.row.resultMsg = params.row.resultCode === 1 ? '成功' : '失败'
                            }
                            return h('span', {}, params.row.resultMsg);
                        }
                    },
                    {
                        key: 'executeTime',
                        title: '执行时间',
                        width: 150,
                        align: 'center',
                        render: (h, params) => {
                            return h('span', formateDate(params.row.executeTime * 1000));
                        }
                    },
                    {
                        key: 'executeState',
                        title: '执行状态',
                        width: 120,
                        align: 'center',
                        render: (h, params) => {
                            return h('span', params.row.executeState ? (params.row.executeState == 1 ? '成功' : '失败') : '');
                        }
                    }
                ],
                logTableHeight: 400,
                logTableData: [],
                currentDevice:'',
                currentICCID:'',
                smsContent:'',
                isSingleSendSms:true,
                currentDeviceNum:0,
                logTotal: 0,
                logCurrent: 1,
                logPageSize: 10,
                isCarNumberDialogShown:false,
                carNumber:''
            };
        },
        methods: {
          showTrackHandeler(row){

            let form = {
              device:row.device,
              shipId:row.shipId,
            }

            this.loading = true;  // 确保在异步操作期间显示加载状态
            fenceDetail(form).then(response => {
              if (response.data && Array.isArray(response.data)){
                this.fenceInfoLsit = response.data;
                row.fenceInfoDTOList = this.fenceInfoLsit;
              } else {
                row.fenceInfoDTOList = [];  // 如果数据不是数组，初始化为空数组
              }
            }).finally(() => {
              this.loading = false;  // 关闭加载状态
              // 将修改后的 row 传递给 showTrack 方法
              this.showTrack(row);
            });
          },



          showTrack(row) {
            this.dialogMapTrackVisible = true;
            this.$nextTick(() => {
              this.$refs.mapTrack.init(row);
            });
          },


          // 获取围栏数据
          getList() {
            this.loading = true;
            let form = Object.fromEntries(
                Object.entries(this.queryParams).map(
                    ([k, v]) => [k, v === '' ? null : v]
                )
            )
            listShipping(form).then(response => {
              this.shippingList = response.data.list;
              this.total = response.data.count;
            }).finally(()=> {
              this.loading = false;
            });
          },
          getFenceList(device){
            this.loading = true;
            let form = {
              device:device,
              pageNum:1,
              pageSize:10
            }



            fenceList(form).then(response => {
            this.fenceList = response.data.list;
              this.total = response.data.count;
            }).finally(()=> {
              this.loading = false;
            });

        },



            bindCarNumber(){
                console.log(this.$data.currentDevice)
                ajax.post('/device/modify_car_number',{
                    device:this.$data.currentDevice,
                    carNumber:this.$data.carNumber
                },res=>{
                    if(res.code===200){
                        this.$Message.success('绑定成功')
                    }else{
                        this.$Message.error(res.message||'车牌绑定失败');
                    }
                })
            },
            //短信发送
            sendSms(row) {
                ajax.post('/xhk_web', {
                    service: 'device/fetch_one',
                    device: row.device
                }, res => {
                    if (res.status === 200) {
                        if(res.body.sim) {
                            this.isSingleSendSms = true;
                            this.currentDevice = res.body.device;
                            this.currentICCID = res.body.iccid;
                            this.showSmsModal = true;
                        }else {
                            this.$Message.error('此设备没有ICCID');
                        }
                    }
                }, err => {
                    this.$Message.error(err.message || '服务器异常，请重试');
                });
            },
            startBatchSendSms(selectionList) {
                if (selectionList && selectionList.length > 0) {
                    this.isSingleSendSms = false;
                    this.currentDeviceNum = selectionList.length;
                    let devices = selectionList.map(item => item.device);
                    this.currentDevice = devices.join(',');
                    this.showSmsModal = true;
                }else {
                    this.$Message.error('请选择要操作的设备');
                }
            },
            startBatchSerStting (selectionList) {
                if (selectionList && selectionList.length > 0) {
                    this.isSingleSendSms = false;
                    this.currentDeviceNum = selectionList.length;
                    let devices = selectionList.map(item => item.device);
                    this.currentDevice = devices.join(',');
                    this.showSerSetting = true
                }else {
                    this.$Message.error('请选择要操作的设备');
                }
            },
            sendSmsDo() {
                ajax.post('/xhk_web', {
                    service: 'device/command/send_sms',
                    devices: this.currentDevice,
                    commandParams:[
                        {"cmd":"sendSMS","params":{"content":this.smsContent}}
                    ]
                }, res => {
                    if (res.status === 200) {
                        if(res.message === 'SUCCESS') {
                            this.$Message.success('短信发送成功');
                        }else {
                            this.$Message.error(res.message || '短信发送失败');
                        }
                    }
                }, err => {
                    this.$Message.error(err.message || '服务器异常，请重试');
                });
            },
            openCardTime(isOpen) {
                if(isOpen) {
                    let endTime = new Date(this.getBeforeYearMonth(-1)+'-01').getTime() - 24*60*60*1000;
                    let endTimeVal = formateDate(endTime).substr(0,10);
                    this.queryData.cardTime = [this.getBeforeYearMonth(0)+'-01',endTimeVal];
                    this.queryData.startValidDate = this.getBeforeYearMonth(0)+'-01 00:00:00';
                    this.queryData.endValidDate = endTimeVal+' 23:59:59';
                }
            },
            changeCardTime(time) {
                this.queryData.startValidDate = time[0] ? time[0]+' 00:00:00' : '';
                this.queryData.endValidDate = time[1] ? time[1]+' 23:59:59' : '';
            },
            getBeforeYearMonth(i) {
                let cur = new Date().getMonth() + 1;
                let curYear = new Date().getFullYear();
                let returnM = cur - i;
                if(returnM < 1) {
                    returnM = returnM + 12;
                    curYear = curYear - 1;
                }else if(returnM > 12) {
                    returnM = returnM - 12;
                    curYear = curYear + 1;
                }
                returnM = returnM < 10 ? '0'+returnM : returnM;
                return curYear + '-' + returnM;
            },
            dataQuery() {
                this.query();
            },
            query(page = 1) {
                this.loading = true;

                let reqData = Object.assign({service: 'device/list'}, this.queryData);
                reqData.devices = this.queryData.devices.replace(/\ +/g, ',').replace(/[\r\n]/g, ',');
                reqData.iccid = this.queryData.iccid.replace(/\ +/g, ',').replace(/[\r\n]/g, ',');
                reqData.deliveryStatus = this.queryData.deliveryStatus === '0' ? '' : this.queryData.deliveryStatus;
                reqData.cardStatus = this.queryData.cardStatus;
                reqData.status = this.queryData.status === '0' ? '' : this.queryData.status;
                reqData.platform = this.queryData.platform === '0' ? '' : this.queryData.platform;
                reqData.pids = this.queryData.pids.filter(Boolean).join(',');
                reqData.expireTime = this.queryData.expireTimeString ? moment(this.queryData.expireTimeString).unix() : '';
                reqData.page = page;
                reqData.pageSize = this.pageSize;
                ajax.post('/device/list', reqData, res => {
                    //客户维度搜索
                    if (this.queryData.uid) {
                        delete this.queryData.uid;
                    }
                    //订单维度搜索
                    if (this.queryData.oid) {
                        delete this.queryData.oid;
                    }
                    if (res.code === 200) {

                        this.tableData = res.data.data;
                        this.total = res.data.total;
                        this.isAll = false;
                    }
                    this.loading = false;
                }, err => {
                    this.loading = false;
                    this.$Message.error(err.message || '服务器异常，请重试');
                });
            },
            getDeviceModeList() {
                ajax.post('/device/get_product_model_list', {

                }, res => {
                    this.loading = false;
                    if (res.code === 200) {
                        //为了使用TreeSelect 的数据格式
                        this.deviceModeList =[ {
                            id: '',
                            label: '全部',
                            isDefaultExpanded:true,
                            children: _.sortBy(res.data,['name']).map(item=>({id:item.id,label:item.name}))
                        }]
                    } else {
                        this.deviceModeList = [];
                    }
                }, err => {
                    this.deviceModeList = [];
                    this.$Message.error(err.message || '服务器异常，请重试');
                });
            },
            getCompanyList() {
                ajax.post('/customer/list', {
                    page:1,
                    pageSize:999,
                }, res => {
                    this.loading = false;
                    if (res.code === 200) {
                        //为了使用TreeSelect 的数据格式
                        this.companyJson = res.data.data;
                    } else {
                        this.companyJson = [];
                    }
                }, err => {
                    this.companyJson = [];
                    this.$Message.error(err.message || '服务器异常，请重试');
                });
            },
            clear() {
                this.queryData = {
                    devices: '',
                    phone: '',
                    company: '',
                    iccid: '',
                    deliveryStatus: '0',
                    cardStatus:'',
                    status: '0',
                    platform: '0',
                    pids:[],
                    cardTime:[],
                    startValidDate:'',
                    endValidDate:'',
                };
            },
            changePage(page) {
                this.query(page);
            },
            handleSelectAll() {
                this.$refs.table.selectAll(this.isAll);
            },
            resetFields(value) { // 重置数据
                this.showDeviceDtlModal = false;
                this.showRateModal = false;
                this.addLoading = false;
                this.$refs[value].resetFields();
            },
            goTrailPage(row) {
                this.$router.push({path: '/devices/trail_back', query: {device: row.device,carNumber:row.carNumber}})
            },

            actionRender(h, params) {
                let dtlBtn = h('Button',
                    {
                        props: {type: 'text'},
                        on: {
                            click: () => {
                                this.showDeviceDetail(params.row);
                            }
                        }
                    }, '设备详情');



                let smsBtn = h('Button',
                    {
                        props: {type: 'text'},
                        on: {
                            click: () => {
                                this.sendSms(params.row);
                            }
                        }
                    }, '短信发送');
                let rrBtn = h('Button',
                    {
                        props: {type: 'text'},
                        on: {
                            click: () => {
                                this.startReportedRate(params.row);
                            }
                        }
                    }, '指令设置');

                let deviceBtn = h('Button',
                    {
                        props: {type: 'text'},
                        on: {
                            click: () => {
                                this.deviceDistri(params.row,'1');
                            }
                        }
                    }, '分配设备');
                let serviceDateBtn = h('Button',
                    {
                        props: {type: 'text'},
                        on: {
                            click: () => {
                                this.serviceDate(params.row, '1');
                            }
                        }
                    }, '服务设置');
                let trailBtn = h('Button',
                    {
                        props: {type: 'text'},
                        on: {
                            click: () => {
                                this.goTrailPage(params.row);
                            }
                        }
                    }, '轨迹回放');
                let carNumberBtn = h('Button',{
                    props:{type:'text'},
                    on:{
                        click:()=>{
                            this.showBindCarNumberDialog(params.row)
                        }
                    }
                },'绑定牌照')
                let logBtn = h('Button',
                    {
                        props: {type: 'text'},
                        on: {
                            click: () => {
                                this.showLogModal(params.row);
                            }
                        }
                    }, '查看日志');
                let temperatureBtn = h('Button',
                    {
                        props: {type: 'text'},
                        on: {
                            click: () => {
                                this.showTemperatureModal(params.row);
                            }
                        }
                    }, '温湿度趋势');

                     let delFenceBtn = h('Button',
                    {
                        props: {type: 'text'},
                        on: {
                            click: () => {
                                this.showDelFenceModal(params.row);
                            }
                        }
                    }, '删除设备围栏');


              let viewFenceBtn = h('Button',
                  {
                    props: {type: 'text'},
                    on: {
                      click: () => {
                        this.viewCurrentFence(params.row);
                      }
                    }
                  }, '当前围栏配置');

                return this.renderGear(h, rrBtn, deviceBtn, trailBtn,carNumberBtn,delFenceBtn,viewFenceBtn);
                // return this.renderGear(h, rrBtn,serviceDateBtn, dtlBtn, trailBtn, logBtn,smsBtn,temperatureBtn);
            },
            beforeUploadHandle() {
                this.uploadPercentage = 0;
                this.uploadStatus = 'active';
                this.uploadMessage = '';
                this.showUploadState = true;
                return true;
            },
            uploadSuccessHandle(response) {
                this.uploadPercentage = 100;
                this.uploadMessage = response.status === 200 ? response.body : response.message;
                this.uploadStatus = response.status === 200 ? 'success' : 'wrong';
                if (response.status === 200) {
                    this.query();
                }
            },
            //分配设备
            deviceDistri(row,type) {
                console.log(row)
                this.showDeviceFlag = type;
                this.deviceDistriCompanyId = '';
                if(type === '1') {
                    this.showDeviceDistri = true;
                    this.daloagDevice = row.device;

                }else {
                    if (row && row.length > 0) {
                        this.currentDeviceNum = row.length;
                        let devices = row.map(item => item.device);
                        this.daloagDevice = devices.join(',');
                        this.showDeviceDistri = true;
                    }else {
                        this.$Message.error('请选择要操作的设备');
                    }
                }
            },
            // 服务设置
            serviceDate(row, type) {
                this.daloagTime = ''
                this.showSerFlag = type
                if (type == '1') {
                    this.showSerSetting = true
                    if (row.expireTime) {
                        this.daloagTime = formateDate(row.expireTime * 1000)
                    }
                    this.daloagDevice = row.device
                } else {
                    if (row && row.length > 0) {
                        this.isSingleSendSms = false;
                        this.currentDeviceNum = row.length;
                        let devices = row.map(item => item.device);
                        this.daloagDevice = devices.join(',');
                        this.daloagTime = this.currentDateLater()
                        console.log(this.daloagTime)
                        this.showSerSetting = true
                    }else {
                        this.$Message.error('请选择要操作的设备');
                    }
                }

            },
            setDeviceDistri () {
                if(!this.deviceDistriCompanyId) {
                    this.$Message.error('请选择要分配的组织');
                    return;
                }
                ajax.post('/device/allocate', {
                    cid:this.deviceDistriCompanyId,
                    devices: this.daloagDevice,
                }, res => {
                    if (res.code === 200) {
                        this.$Message.success('分配设备成功');
                        this.query();
                    }
                }, err => {
                    this.$Message.error(err.message || '服务器异常，请重试');
                });
            },
            currentDateLater() {
                var date = new Date();
                var y = date.getFullYear() + 1;
                var m = date.getMonth() + 1;
                // m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                // d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                // h = h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                var second = date.getSeconds();
                minute = minute < 10 ? ('1' + minute) : minute;
                second = second < 10 ? ('0' + second) : second;
                return y + '-' + m + '-' + d;
            },
            sendSetting () {
                ajax.post('/xhk_web', {
                    service: 'device/modify_expire_time',
                    devices: this.daloagDevice,
                    expireTime: moment(this.daloagTime).unix()
                }, res => {
                    if (res.status === 200) {
                        this.query();
                    }
                }, err => {
                    this.$Message.error(err.message || '服务器异常，请重试');
                });
            },
            showBindCarNumberDialog({device}){
                this.currentDevice = device
                this.isCarNumberDialogShown=true;
            },
            //查看日志
            showLogModal({device}) {
                this.showLogState = true;
                this.logDevice = device;
                this.handleTabsChange(0)
            },

          // 查看正在运行围栏
          viewCurrentFence({device}) {

            this.showCurrentFence = true;
            this.logDevice = device;
            this.getFenceList(device);

          },


            showTemperatureModal({device}) {
                let startTime = new Date(getDayBefore(0)+' 00:00:00').getTime()/1000;
                let endTime = new Date(getDayBefore(0)+' 23:59:59').getTime()/1000;
                ajax.post('/xhk_web', {
                    service: 'device/humd/list',
                    device: device,
                    startTime:startTime,
                    endTime:endTime,
                }, res => {
                    if (res.status === 200) {
                        if(!res.body || res.body.length < 1) {
                            this.$Message.error('暂时没有温湿度数据');
                            return;
                        }
                        this.tabActiveTem = 0;
                        this.showTemperatureState = true;
                        let chartNode = document.getElementById('temperature_echarts');
                        let chart = echarts.init(chartNode);
                        this.chartCurrentData = this.transformChartData(res);
                        let chartOptions = this.createChartOptions('temperature', this.chartCurrentData);
                        chart.setOption(chartOptions);

                    }
                }, err => {
                    this.$Message.error(err.message || '服务器异常，请重试');
                });

            },
              showDelFenceModal({device}) {
                ajax.post('/xhk_web', {
                    service: 'device/device_delete',
                    devices: device,
                }, res => {
                     this.$Message.success('设备:['+device+']围栏删除成功');
                }, err => {
                    this.$Message.error(err.message || '服务器异常，请重试');
                });

            },

            handleTabsChange(name) {
                this.tabActiveIndex = name;
                this.changeLogPage()
            },


            handleTabsChangeTem(name) {
                this.tabActiveTem = name;
                if(name === 1) {
                    setTimeout(()=> {
                        let chartNode2 = document.getElementById('humidity_echarts');
                        let chart2 = echarts.init(chartNode2);
                        let chartOptions2 = this.createChartOptions('humidity', this.chartCurrentData);
                        chart2.setOption(chartOptions2);
                    },10)
                }
            },

            changeLogPage(page = 1) {
                this.logLoading = true;
                this.logCurrent = page;
                switch (this.tabActiveIndex) {
                    case 0:
                        this.getLogTableData("/device/get_operation_log", page)
                        break;
                    case 1:
                        this.getLogTableData2("/device/get_command_log", page)
                        break;
                    default:
                        break;
                }

            },
            //操作日志
            getLogTableData(serviceName, page) {
                this.$ajax.get('/xhk_web?service=' + serviceName + '&page=' + page + '&size=' + this.logPageSize + '&deviceNumber=' + this.logDevice, res => {
                    if (res.status === 200) {
                        this.logTableData = res.body.elements;
                        this.logTotal = res.body.totalElementsCount;
                    }
                    this.logLoading = false;
                }, err => {
                    this.$Message.error(err.message || '服务器异常，请重试');
                });
            },
            //指令日志
            getLogTableData2(serviceName, page) {
                this.$ajax.get('/xhk_web?service=' + serviceName + '&page=' + page + '&size=' + this.logPageSize + '&device=' + this.logDevice, res => {
                    if (res.status === 200) {
                        this.logTableData = res.body.elements;
                        this.logTotal = res.body.totalElementsCount;
                    }
                    this.logLoading = false;
                }, err => {
                    this.$Message.error(err.message || '服务器异常，请重试');
                });
            },
            syncIotDevice() {
                this.$ajax.get('/xhk_web?service=iot.sync.device_list', res => {
                    if (res.status === 200) {
                        this.$Message.success('数据同步任务可能需要几分钟，请稍后刷新页面查看！');
                    }
                }, err => {
                    this.$Message.error(err.message || '服务器异常，请重试');
                });
            },
             exportExcel() {
            let { queryData } = this;
            let reqData = { ...queryData };
            reqData.devices = queryData.devices.replace(/\ +/g, ',').replace(/[\r\n]/g, ',');
            reqData.iccid = queryData.iccid.replace(/\ +/g, ',').replace(/[\r\n]/g, ',');
            reqData.deliveryStatus = queryData.deliveryStatus === '0' ? '' : queryData.deliveryStatus;
            reqData.status = queryData.status === '0' ? '' : queryData.status;
            reqData.platform = queryData.platform === '0' ? '' : queryData.platform;
            reqData.deviceMode = queryData.deviceMode === '0' ? '' : queryData.deviceMode;
            reqData.pids = this.queryData.pids && this.queryData.pids.filter(Boolean).join(',');
            ajaxBlob.post('/device/export', {
                page: 1,
                pageSize: 10000,
                fileName: encodeURIComponent('设备列表'),
                ...reqData
            }, (res) => {
               convertToExcel([res.data], '设备列表')
            }, (err) => {

               console.log(err)
            })
            // const {href} = this.$router.resolve({
            //     path: ,
            //     query: {
            //         page: 1,
            //         pageSize: 10000,
            //         fileName: encodeURIComponent('设备列表'),
            //         ...reqData
            //     }
            // });
            //window.open(href, '_blank');
        },
            //组装图表数据
            transformChartData(res) {
                let dateArr = [];
                let temperature = [];
                let humidity = [];
                let items = res.body;
                if (items && items.length) {
                    items.forEach(function (item) {
                        dateArr.push(formateDate(item.created * 1000));
                        item.temperature !== null && temperature.push(item.temperature);
                        item.humidity !== null && humidity.push(item.humidity);
                    });
                }
                return {
                    dateArr: dateArr,
                    temperature: temperature,
                    humidity: humidity,
                }
            },

            //设置图表数据
            createChartOptions(type, data) {
                let opts = CHART_OPTIONS[type];
                return {
                    width: 'auto',
                    title: {
                        text: '',
                        x: 'center',
                        align: 'center',
                        top: 'top',
                        textStyle: {
                            color: "#333"
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                    },
                    dataZoom: [
                        {
                            type: 'slider',
                            start: 0,
                            end: 100,
                            showDetail: false,
                        },
                        {
                            type: 'inside',
                            start: 0,
                            end: 100,
                        }
                    ],
                    xAxis: {
                        splitLine: {show: false},
                        axisTick: {
                            alignWithLabel: true
                        },
                        data: data.dateArr,
                        axisLine: {
                            onZero: false,
                            lineStyle: {
                                color: "#999999",
                                width: 1
                            }
                        }
                    },
                    yAxis: {
                        position: 'left',
                        splitLine: {show: true, lineStyle: {color: '#EBEBEB', type: 'dotted'}},
                        type: 'value',
                        name: opts.legend_name,
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: true,
                            lineStyle: {
                                color: "#999999",
                                width: 1
                            }
                        },
                        axisLabel: {
                            show: true,
                            color: "#999999"
                        }
                    },
                    visualMap: {
                        type: 'piecewise',
                        orient: 'horizontal',
                        textGap: 4,
                        itemGap: 15,
                        top: 10,
                        right: 5,
                        splitNumber: 3,
                        itemWidth: 14,
                        itemHeight: 10,
                        textStyle: {color: '#999999'},
                        outOfRange: {
                            color: '#999'
                        }
                    },
                    series: {
                        name: opts.legend_name,
                        type: 'line',
                        data: data[type],
                        markLine: {
                            lineStyle: {normal: {color: '#19BE6B', type: 'dotted'}},
                            data: [
                            ]
                        }
                    },
                };
            }
        },
        mounted() {
            this.getDeviceModeList();
            this.getCompanyList();
            this.query();
        },
        beforeRouteUpdate() {
            this.clear();
            this.query();
        }
    };
</script>
